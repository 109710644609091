import React, { useState } from "react";
import  { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavBar from './Components/NavBar/NavBar';
import Main from "./Components/Main/Main";
import Rules from "./Components/Rules/Rules";
import Applications from "./Components/Applications/Applications";
import Connect from "./Components/Connect/Connect";
import ThemeContext from "./Components/DarkTheme/DarkTheme";
import Whitelist from "./Components/Applications/Whitelist/Whitelist";
import Play from "./Components/Connect/Play";
import Error from "./Components/Error/Error";
import AboutUs from "./Components/AboutUs/AboutUs";
import Footer from "./Components/Footer/Footer";
import PlayHoursPage from "./Components/PlayHours/PlayHoursPage";
import Admin from "./Components/Admin/Admin";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from "./Components/Login/Login";




let App = () => {
  const [darkMode, setDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };
  const [ip, setIp] = useState(null);
  const sitemap = `<?xml version="1.0" encoding="UTF-8"?>
<urlset
      xmlns="http://www.sitemaps.org/schemas/sitemap/0.9"
      xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
      xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9
            http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
<!-- created with Free Online Sitemap Generator www.xml-sitemaps.com -->


<url>
  <loc>https://www.malluroleplay.com/</loc>
  <lastmod>2023-03-02T12:00:17+00:00</lastmod>
</url>


</urlset>`;

  return (
    <div className={darkMode ? 'dark' : ""}>
      <div className="dark:bg-gray-900 min-h-screen   ">

        <Router>
          <ThemeContext.Provider value={{ darkMode, toggleDarkMode }}>

            <NavBar />
            <Routes>

              <Route path='/' element={<Main />} />

              <Route path='/rules' element={<Rules />} />
              <Route path='/playhours' element={<PlayHoursPage />} />
              <Route path='/application' element={<Applications />} />
              <Route path='/connect' element={<Connect />} />
              <Route path='/whitelist' element={<Whitelist />} />
              <Route path='/play' element={<Play />} />
              <Route path='/aboutus' element={<AboutUs />} />
              <Route path="/sitemap" render={() => sitemap} />
              <Route path='/error' element={<Error />} />
              <Route path='/admin' element={<Admin />} />
              <Route path='/login' element={<Login />} />
              

            </Routes>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />
          </ThemeContext.Provider>
        </Router>




      </div>
    </div>
  );
}

export default App;
// import NavBar from "./Components/NavBar/NavBar";
// import Applications from "./Components/Applications/Applications";