import React, { useState, useEffect,useContext } from 'react';
import axios from 'axios';
import Loader from './Loader';
import ThemeContext from '../DarkTheme/DarkTheme';

function PlayHours() {
  const [data, setData] = useState({});
  const { darkMode } = useContext(ThemeContext)
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(
        'https://www.malluroleplay.com/api/fivem' // Replace with your API URL
      ).then(result =>{
        setData(result.data.players);
        setIsLoading(false);
      });

      
    };

    fetchData();
  }, []);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert from seconds to milliseconds
    const offset = 330; // Offset in minutes for UTC+5:30
    const adjustedDate = new Date(date.getTime() + offset * 60 * 1000);
    const options = { timeZone: 'Asia/Kolkata' }; // Set the timezone to UTC+5:30
    return adjustedDate.toLocaleString('en-US', options); // Format the date and time as a string
  };

  const formatTime = (seconds) => {
    const minutes = (seconds / 60).toFixed(2);
    return `${minutes} Mins`;
  };

  const filteredData = Object.values(data)
  .filter((player) => {
    return Object.values(player).some((value) =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    );
  })
  .sort((a, b) => b.playtime - a.playtime);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={darkMode ? 'dark' : ""}>
        <div className="text-center pt-9 mr-5 rounded-xl backdrop-blur-sm w-full" id="mytable">
          <input
            type="text"
            placeholder="Search"
            onChange={(e) => setSearchTerm(e.target.value)}
            className="rounded-md p-2 mt-4 mb-2"
          />
          <div className="table-container overflow-y-scroll h-96">
            <table className="table-auto text-center border-collapse w-full dark:text-gray-100">
              <thead>
                <tr>
                  <th className="border p-2">Username</th>
                  <th className="border p-2">Playtime</th>
                  {/* <th className="border p-2">Last Leave</th> */}
                  {/* <th className="border p-2">Last Join</th> */}
                </tr>
              </thead>
              <tbody>
                {filteredData.map((player) => (
                  <tr key={player.steam_hex} className="hover:bg-gray-200">
                    <td className="border p-2">{player.username}</td>
                    <td className="border p-2">{formatTime(player.playtime)}</td>
                    
                    {/* <td className="border p-2">{formatDate(player.last_leave)}</td> */}
                    {/* <td className="border p-2">{formatDate(player.last_join)}</td> */}
                  </tr>
                ))}
                {filteredData.length === 0 && (
                  <tr>
                    <td colSpan="4" className="border border-gray-500 p-2">
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        </div>
      )}
    </>
  );
}

export default PlayHours;









// const filteredData = Object.values(data).filter((player) => {
//   return Object.values(player).some((value) =>
//     value.toString().toLowerCase().includes(searchTerm.toLowerCase())
//   );
// });






// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Loader from './Loader';

// function PlayHours() {
//   const [data, setData] = useState({});
//   const [searchTerm, setSearchTerm] = useState('');
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     const fetchData = async () => {
//       const result = await axios.get(
//         'https://connect-mrp.in/api/fivem' // Replace with your API URL
//       );
      
//       setData(result.data.players);
//       // const tokenAuth= 'ey312JhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiIxMjM0NTY3ODkwIiwicm9sZXMiOlsiYWRtaW4iLCJlZGl0b3IiXSwicGVybWlzc2lvbnMiOlsicmVhZCIsIndyaXRlIl19.G_f8QzyS6G-piNE1LoX9fwrc_NKZT4Oz4s4sNQR8xg4'
//       // const fivem = await axios.get('http://15.235.165.39:30120/Prefech_playTime/info',{
//       //   method : 'get',
//       //   headers : {
//       //     Authentication : `token ${tokenAuth}` 
//       //   }
//       // })
//       // console.log(fivem);
//       setIsLoading(false);
//     };
    

//     fetchData();
//   }, []);

//   // const formatDate = (timestamp) => {
//   //   const date = new Date(timestamp * 1000); // Convert from seconds to milliseconds
//   //   return date.toLocaleString(); // Format the date and time as a string
//   // };
//   const formatDate = (timestamp) => {
//     const date = new Date(timestamp * 1000); // Convert from seconds to milliseconds
//     const offset = 330; // Offset in minutes for UTC+5:30
//     const adjustedDate = new Date(date.getTime() + offset * 60 * 1000);
//     const options = { timeZone: 'Asia/Kolkata' }; // Set the timezone to UTC+5:30
//     return adjustedDate.toLocaleString('en-US', options); // Format the date and time as a string
//   };


//   const formatTime = (seconds) => {
//     const minutes = (seconds / 60).toFixed(2);
//     return `${minutes} Mins`;
//   };

//   const filteredData = Object.values(data).filter((player) => {
//     return Object.values(player).some((value) =>
//       value.toString().toLowerCase().includes(searchTerm.toLowerCase())
//     );
//   });

//   return (
//     <>
//       {isLoading ? (
//         <Loader />
//       ) : (
//         <div className="text-center pt-9 mr-5 rounded-xl backdrop-blur-sm w-full" id="mytable">
//           <input
//             type="text"
//             placeholder="Search"
//             onChange={(e) => setSearchTerm(e.target.value)}
//             className="rounded-md p-2 mt-4 mb-2"
//           />
//           <div className="table-container overflow-y-scroll h-96">
//             <table className="table-auto text-center border-collapse w-full">
//               <thead>
//                 <tr>
//                   <th className="border p-2">Username</th>
//                   <th className="border p-2">Playtime</th>
//                   <th className="border p-2">Last Leave</th>
//                   <th className="border p-2">Last Join</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {filteredData.map((player) => (
//                   <tr key={player.steam_hex} className="hover:bg-gray-200">
//                     <td className="border p-2">{player.username}</td>
//                     <td className="border p-2">{formatTime(player.playtime)}</td>
//                     <td className="border p-2">{formatDate(player.last_leave)}</td>
//                     <td className="border p-2">{formatDate(player.last_join)}</td>
//                   </tr>
//                 ))}
//                 {filteredData.length === 0 && (
//                   <tr>
//                     <td colSpan="4" className="border border-gray-500 p-2">
//                       No data available
//                     </td>
//                   </tr>
//                 )}
//               </tbody>
//             </table>
//           </div>
//         </div>
//       )}
//     </>
//   );
// }

// export default PlayHours;
