import React , {useContext} from 'react'
import PlayHours from './PlayHours'
import ThemeContext from '../DarkTheme/DarkTheme';

function PlayHoursPage() {
    const { darkMode } = useContext(ThemeContext)
    return (
        <div className={darkMode ? 'dark' : ""}>
        <div className='text-center pt-9 shadow-2xl mr-5 rounded-xl backdrop-blur-sm min-h-screen dark:bg-gray-800 '>
            <h1 className='text-2xl dark:text-gray-100'>Play Hours</h1>
            <PlayHours />
        </div>
        </div>
    )
}

export default PlayHoursPage