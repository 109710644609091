import React from 'react'

function Loader() {
  return (
    <div class="border border-blue-300 shadow rounded-md p-4 max-w-md w-full mx-auto">
    <table class="w-full divide-y divide-gray-200">
      <thead>
        <tr>
          <th class="w-1/3 h-12 bg-gray-200 animate-pulse"></th>
          <th class="w-1/3 h-12 bg-gray-200 animate-pulse"></th>
          <th class="w-1/3 h-12 bg-gray-200 animate-pulse"></th>
        </tr>
      </thead>
      <tbody class="divide-y divide-gray-200">
        <tr>
          <td class="h-12 bg-gray-200 animate-pulse"></td>
          <td class="h-12 bg-gray-200 animate-pulse"></td>
          <td class="h-12 bg-gray-200 animate-pulse"></td>
        </tr>
        <tr>
          <td class="h-12 bg-gray-200 animate-pulse"></td>
          <td class="h-12 bg-gray-200 animate-pulse"></td>
          <td class="h-12 bg-gray-200 animate-pulse"></td>
        </tr>
        <tr>
          <td class="h-12 bg-gray-200 animate-pulse"></td>
          <td class="h-12 bg-gray-200 animate-pulse"></td>
          <td class="h-12 bg-gray-200 animate-pulse"></td>
        </tr>
        <tr>
          <td class="h-12 bg-gray-200 animate-pulse"></td>
          <td class="h-12 bg-gray-200 animate-pulse"></td>
          <td class="h-12 bg-gray-200 animate-pulse"></td>
        </tr>
        <tr>
          <td class="h-12 bg-gray-200 animate-pulse"></td>
          <td class="h-12 bg-gray-200 animate-pulse"></td>
          <td class="h-12 bg-gray-200 animate-pulse"></td>
        </tr>
      </tbody>
    </table>
  </div>
  

  )
}

export default Loader