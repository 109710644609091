import React from 'react'
import { motion } from "framer-motion"
import Footer from '../Footer/Footer';
import { RxDiscordLogo } from 'react-icons/rx'
import { SlSocialYoutube } from 'react-icons/sl'
import { FiInstagram } from 'react-icons/fi'
import FooterSticky from '../Footer/FooterSticky';


let Rules = (props) => {

    const { darkMode } = props;
    return (
        <div>

            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                    delay: 0.5,
                    y: { duration: 1 },
                    default: { ease: "easeIn" }
                }}
                className='text-center pt-5 p-20 overflow-hidden'>
                <h1 className='text-5xl underline dark:text-gray-100'>Rules</h1>
                <div className='mt-7'>
                    <h2 className='text-2xl font-bold dark:text-gray-100'>General Rules</h2>
                    <p className='mt-3 text-gray-400'>
                        Our server mainly focuses on serious RP and we expect serious RP from your side and we strictly believe in character progression and interaction.
                        Kindly read the rules mentioned below to help you with your time at MRP.
                    </p>
                    <p className='mt-3 text-gray-400'>
                        Respect every player in Mallu Roleplay inside and outside of the game especially female characters,
                        as you give respect in real life. Do not speak vulgarly or forcefully use emotes such as hug, kiss, etc.,
                        without their concern.
                    </p>
                    <p className='mt-3 text-gray-400'>
                        Steam or discord names or in-game character names that look offensive will receive warning or ban. Everyone is requested to keep appropriate names. If you continue to join after being warned with an offensive name, a server
                        removal/ban will be considered without further notice.
                    </p>
                    <p className='mt-3 text-gray-400'>
                        Defaming our server or individual player or a team in live streams, videos, inside game or outside game will receive temporary ban or permanent ban based on severity. Promoting other servers in-game or in any means or
                        spamming the server members with unnecessary advertisements of any kind is strictly prohibited. Kindly respect other's privacy and act accordingly.
                    </p>
                    <p className='mt-3 text-gray-400'>
                        At any moment of time, you are not allowed to judge anyone's RP.
                        If you witness someone breaking the rules first complete the scenario properly and contact a Helping Hands in discord by raising a ticket. Please try to report in-game name/steam name of the defaulted person, what happened, and video proof if possible. After raising a ticket don't raise the problem again inside the game or in OOC.
                        You are not allowed to talk about rules or fail RP inside the game if spoken server action will be taken.
                    </p>
                    <p className='mt-3 text-gray-400'>
                        Under no circumstances you are not permitted to disclose your whitelisted account (Steam, Discord) to anyone.
                        Remember that anyone else playing in your account is also considered as account sharing.

                    </p>
                    <p className='mt-3 text-gray-400'>
                        All the rules are subject to change based on the feedback and experience that will be decided by admins if required.

                    </p>
                    <p className='mt-3 text-gray-400'>
                        Above all, remember that this is just a game, and don't play with personal grudges against anyone. If we find anyone doing so, we will take the necessary actions without warning.

                    </p>

                    <h2 className='text-2xl mt-6 font-bold dark:text-gray-100'>Roleplay Rules</h2>
                    <div className='lg:grid lg:grid-cols-2 lg:gap-8'>
                        <div className='rounded-xl shadow-xl'>
                            <p className=' text-2xl font-semibold mt-3 dark:text-gray-100'>RDM/VDM</p>
                            <p className='mt-3 text-gray-400'>(Random death match): Killing/attempting to kill a citizen without any intent or roleplay.</p>
                            <p className='mt-2 mb-2 text-gray-400'>(Vehicle deathmatch): Using a vehicle to kill/attempt to kill without valid reasoning or initiation.</p>
                        </div>

                        <div className='rounded-xl shadow-xl mt-3'>
                            <p className='text-2xl font-semibold  dark:text-gray-100'>COMBAT LOGGING</p>
                            <p className='mt-3 mb-2 text-gray-400'>Do not quit/log out in the middle of a scenario or when you are dead in a scenario.</p>
                        </div>

                        <div className='rounded-xl shadow-xl mt-3'>
                            <p className='text-2xl font-semibold  dark:text-gray-100'>LORE BREAKING</p>
                            <p className='mt-3 mb-2 text-gray-400'>Lore breaking is breaking another player’s roleplay situation by directly entering the scenario or by any other means. For example : Once you are under custody, do not teach the officers what to do. They will do their duty at their own pace and according to their RP. If their investigation takes time, you have to bear with it till their RP is done. You cannot force them under any circumstances.</p>
                        </div>


                        <div className='rounded-xl shadow-xl mt-3'>
                            <p className='text-2xl font-semibold  dark:text-gray-100'>METAGAMING</p>
                            <p className='mt-3 mb-2 text-gray-400'>Directly involved in a situation that your character did not learn in-game. For Example : Getting information through WhatsApp Chats, Discord, Live Streams and involving in that situation.</p>
                        </div>

                        <div className='rounded-xl shadow-xl mt-3'>
                            <p className='text-2xl font-semibold  dark:text-gray-100'>POWER GAMING</p>
                            <p className='mt-3 mb-2 text-gray-400'>You are not allowed to abuse the in-game mechanics to give an advantage over the other players.​​For example, forcing a player to do something for your own personal gains is considered as power gaming.</p>
                        </div>

                        <div className='rounded-xl shadow-xl mt-3'>
                            <p className='text-2xl font-semibold  dark:text-gray-100'>FEAR RP</p>
                            <p className='mt-3 mb-2 text-gray-400'>Describes the character's fear of anything within the server. This means that you are to value your life at all times and act accordingly in situations. For example, if you have a gun/taser pointed at you, then you are to listen to commands given by the person holding the gun or anyone affiliated with that person to ensure your own survival.</p>
                        </div>


                        <div className='rounded-xl shadow-xl mt-3'>
                            <p className='text-2xl font-semibold  dark:text-gray-100'>BUG EXPLOITING</p>
                            <p className='mt-3 mb-2 text-gray-400'>Bugs are unavoidable because the server is constantly being updated. You are not allowed to exploit any of these in-game bugs and use them to your advantage. If you discover a bug that can be exploited, you must report it immediately to any available staff member, who will make sure it is fixed. Trying to exploit bugs of any sort will result in a permanent ban. For example, when you are caught during a situation, if your friend approaches you or stands near a wall and you give him the money, this occurs without the awareness of other players and without any correct roleplay.</p>
                        </div>


                        <div className='rounded-xl shadow-xl mt-3'>
                            <p className='text-2xl font-semibold  dark:text-gray-100'>OOC</p>
                            <p className='mt-3 mb-2 text-gray-400'>RP stopping technical issues and other concerns that cannot be handled in game. Avoid OOC conversation in-game. This will result in a direct kick and further bans if you don't.</p>
                        </div>


                        <div className='rounded-xl shadow-xl mt-3'>
                            <p className='text-2xl font-semibold  dark:text-gray-100'>IN-PERSON ROBBERY / HOLDING UP ANOTHER PLAYER</p>
                            <p className='mt-3 mb-2 text-gray-400'>You are allowed to only steal from people if there's a proper roleplay. You cannot just go to an unknown person for the means of robbing and point a gun at him. You cannot rob the same player that same day. If you want to kidnap a person there must be at least 2 officers on duty. NOTE : You are not allowed to rob a police officer or ems while on duty. And note that you can rob only inventory items of the player.</p>
                        </div>


                        <div className='rounded-xl shadow-xl mt-3'>
                            <p className='text-2xl font-semibold  dark:text-gray-100'>NON-ROLEPLAY (NRP)</p>
                            <p className='mt-3 mb-2 text-gray-400'>Actions that are unrealistic or promote poor quality roleplay are considered as non-roleplay. Players are required to remain IC at all times. There may be many loopholes in the rules so try to do your RP accordingly.</p>
                        </div>


                        <div className='rounded-xl shadow-xl mt-3'>
                            <p className='text-2xl font-semibold  dark:text-gray-100'>NEW LIFE RULE</p>
                            <p className='mt-3 mb-2 text-gray-400'>Upon respawning, you forget everything that led to your demise. You may only remember the events if you are revived by ems (Not applicable to bot revive or admin revived cases). You cannot use the information that you should have forgotten. This includes returning to where the situation occurred, attempting to get revenge on the other party, or going to the police to report the situation, etc.</p>
                        </div>

                        <div className='rounded-xl shadow-xl mt-3'>
                            <p className='text-2xl font-semibold  dark:text-gray-100'>COP BAITING</p>
                            <p className='mt-3 mb-2 text-gray-400'>Baiting a police officer to initiate a fight or a chase by provoking them or by displaying vehicle stunts in front of a PD officer will result in severe action. For example: If a Police officer calls you for a traffic stop, either stop and answer or just plainly try to get away. Avoid doing things like calling backup from your gang members or taking them to your gang place just to shoot them along with your gang mates etc, this will come under Cop Baiting.</p>
                        </div>

                        <div className='rounded-xl shadow-xl mt-3'>
                            <p className='text-2xl font-semibold  dark:text-gray-100'>NOT VALUING LIFE</p>
                            <p className='mt-3 mb-2 text-gray-400'>Just because you are friends with a person, it does not permit you to kill them for no reason. Everything must be RP 'd out to the fullest, or you will receive consequences</p>
                        </div>

                        <div className='rounded-xl shadow-xl mt-3'>
                            <p className='text-2xl font-semibold  dark:text-gray-100'>SAFE ZONE</p>
                            <p className='mt-3 text-gray-400'>You are not allowed to do any kind of illegal activities in this area and you are not allowed to fire into the zone from outside or inside the zone . Don't enter these safe zones after trigger situations or in an active situation, you may use other hideouts across the city. You are not allowed to start a situation in these safe zones</p>
                            <p className='mt-3 mb-2 text-gray-400'>Safe zone areas - <span className='text-red-600'>Main garage, All hospitals, All police stations, Jail, GovernmentOffice</span></p>
                        </div>


                    </div>



                    <p className='mt-3 text-gray-400'>No one is allowed to use voice chat or /me while dead; if this is reported, it will be considered a fail RP.</p>

                    <h2 className='text-2xl mt-6 font-bold dark:text-gray-100'>CRIMINAL ACTIVITY RULES</h2>
                    <p className='mt-3 text-gray-500  dark:text-gray-400'>
                        If you end up in a situation in water, then you are not allowed to hold for more than 3 minutes in water as an option for a getaway. This is considered as FRP. However, using a boat as a getaway vehicle does not come under this.
                        You cannot start shooting at police just for attempting to pull you over. Such actions will be classed as clear RDM.
                        You must have all robbery tools on hand while committing a heist. You are unable to demand them from the police.
                        <br />
                        <br />
                        You should not use the vale service to retrieve your vehicle during an active situation.
                        You cannot kidnap or take a fight with a person for silly reasons like unknowingly pitting your vehicle, etc.
                        <br />
                        <br />
                        Police are familiar with the rules. Just because you've read the rules or are acting as the cops in another character doesn't mean you should start ordering others around. Go along with the flow and raise a ticket later if the police commit any fail RP.
                        You should only demand proper things according to the situations and cannot simply provoke the officers present there by laying down meaningless & non-acceptable demands.
                        <br />
                        <br />
                        If you think the opposing player is intentionally trying to provoke you. You must record your POV and do not provoke them back to create a situation.
                        In extreme case situations and with a solid RP reason, if you have to take an officer as a hostage, make sure there are at least 8 other officers on duty.
                        During any situation, if you aim at a police officer or give a warning fire to the police officer, they can shoot you down directly without any prior warning.

                    </p>

                    <h2 className='text-2xl mt-6 font-bold dark:text-gray-100'>USE OF SMOKE GRENADES</h2>
                    <p className='mt-3 text-gray-500  dark:text-gray-400'>
                        For Turf : Both teams are allowed to use a total of 5 smoke grenades in Turf.
                        <br />
                        For Code99 : A total of 4 smoke grenades can be used by Police. Smokes cannot be used by the robbers in this situation.
                        <br />
                        Use of smoke grenades other than the above mentioned situations are considered as FRP.
                    </p>

                    <h2 className='text-2xl mt-6 font-bold dark:text-gray-100'>WARNING FIRE</h2>
                    <p className='mt-3 text-gray-500 dark:text-gray-400'>
                        If you want to start a shooting scenario but the other party isn't aware, you'll have to give him a chance by suppressing fire and giving them time to react to the shootout. Suppressing fire does not mean firing one bullet into the sky and the other into the head.​ You must give time to clear the situation or count till 30 before killing them.
                        <br />
                        NOTE : If a team/gang declares open war on another team/gang, you do not need to give warning fire.
                    </p>
                    <h2 className='text-2xl mt-6 font-bold dark:text-gray-100'>ROBBERY RULES</h2>

                    <div className='lg:grid lg:grid-cols-3 lg:gap-4 mt-6'>
                        <div className='rounded-xl shadow-xl mt-3'>
                            <p className='text-2xl font-semibold dark:text-gray-100 '>CAR ROBBERY</p>
                            <p className='mt-3 mb-2 text-gray-500  '>Maximum of 2 players will be allowed in this situation<br />
                                Minimum number of hostages required are 0.<br />
                                Maximum number of escaping vehicles allowed are 1<br />
                                You are only permitted to use a normal pistol.
                            </p>
                        </div>

                        <div className='rounded-xl shadow-xl mt-3'>
                            <p className='text-2xl font-semibold  dark:text-gray-100'>SHOP ROBBERY</p>
                            <p className='mt-3 mb-2 text-gray-500 dark:text-gray-400'>Maximum of 2 players will be allowed in this situation<br />
                                Minimum number of hostages required are 1.<br />
                                Maximum number of escaping vehicles allowed are 1.<br />
                                You are only permitted to use a normal pistol.
                            </p>
                        </div>

                        <div className='rounded-xl shadow-xl mt-3'>
                            <p className='text-2xl font-semibold  dark:text-gray-100'>FLEECA ROBBERY</p>
                            <p className='mt-3 mb-2 text-gray-500 dark:text-gray-400'>Maximum of 4 players will be allowed in this situation<br />
                                Minimum number of hostages required are 1.<br />
                                Maximum number of escaping vehicles allowed are 2.<br />
                                You are only permitted to use a SMG/Pistol.</p>
                        </div>

                        <div className='rounded-xl shadow-xl mt-3'>
                            <p className='text-2xl font-semibold  dark:text-gray-100'>JEWEL ROBBERY </p>
                            <p className='mt-3 mb-2 text-gray-500 dark:text-gray-400'>
                                Maximum of 4 players will be allowed in this situation<br />
                                Minimum number of hostages required are 1.<br />
                                Maximum number of escaping vehicles allowed are 2.<br />
                                You are only permitted to use an AR / Normal Pistol.</p>
                        </div>
                        <div className='rounded-xl shadow-xl mt-3'>
                            <p className='text-2xl font-semibold  dark:text-gray-100'>BOBCAT ROBBERY</p>
                            <p className='mt-3 mb-2 text-gray-500 dark:text-gray-400'>Maximum of 4 players will be allowed in this situation.<br />
                                You are only permitted to use 400R & SMG Only.<br />
                                Minimum number of hostages required are 1.<br />
                                Maximum number of escaping vehicles allowed are 2.</p>
                        </div>
                        <div className='rounded-xl shadow-xl mt-3'>
                            <p className='text-2xl  font-semibold  dark:text-gray-100'>MAIN BANK ROBBERY</p>
                            <p className='mt-3 mb-2 text-gray-500 dark:text-gray-400'>Maximum of 6 players will be allowed in this situation.<br />
                                Minimum number of hostages required are 0/2.<br />
                                Maximum number of escaping vehicles allowed are 3 Including chopper.<br />
                                You are only permitted to use AR, SMG or Normal Pistol</p>
                        </div>

                        <div className='rounded-xl shadow-xl mt-3'>
                            <p className='text-2xl font-semibold  dark:text-gray-100'>HUMAN LABS ROBBERY</p>
                            <p className='mt-3 mb-2 text-gray-500 dark:text-gray-400'>
                                Maximum of 6 players will be allowed in this situation.<br />
                                Minimum number of hostages required are 0/2.<br />
                                Maximum number of escaping vehicles allowed are 3 Including choppers.<br />
                                You are only permitted to use AR, SMG or Normal Pistol
                            </p>
                        </div>
                        <div className='rounded-xl shadow-xl mt-3'>
                            <p className='text-2xl font-semibold  dark:text-gray-100'>DRUG HEIST</p>
                            <p className='mt-3 mb-2 text-gray-500 dark:text-gray-400'>TO DO DRUG HEIST REQUIRES A MINIMUM OF 8 COPS ON DUTY.<br />
                                Minimum of 6 players and Maximum of 8 players will be allowed in this situation<br />
                                You are only permitted to use AR & SMG Only.<br />
                                Minimum of 8 & Maximum of 10 police will be allowed to attend the situation</p>
                        </div>
                        <div className='rounded-xl shadow-xl mt-3'>
                            <p className='text-2xl font-semibold  dark:text-gray-100'>CODE 99 SITUATION</p>
                            <p className='mt-3 mb-2 text-gray-500 dark:text-gray-400'>To do code 99 requires a minimum of 8 cops on duty.
                                Code 99 is only permitted in the Main Bank and Humane Laboratories.
                                You are only permitted to use AR & SMG Only.
                                Minimum of 6 players and Maximum of 10 players will be allowed in this situation
                                Maximum of 12 police will be allowed to attend the situation.</p>
                        </div>
                    </div>
                    <p className='mt-3 text-gray-500 dark:text-gray-400'>If you are holding any government official hostage at any moment, make sure you have a very strong RP to back you up since it can be considered an act of terrorism.<br />
                        Do not bring gang vehicles for robbery.<br />
                        If you are in a robbery and you miss the eyesight then you have to wait for 5 minutes in a hideout and then only you can go to store your vehicle in the garage.<br />
                        Attempt robbery due to cool down, when a robbery ends up with a bug and you are not able to rob the money then it will be also considered as robbery inside the game and chase will be continued by police.
                        It’s robbers responsible to escape from the particular location before the police arrive if any cool down or bug occurs. Don’t speak OOC inside the game for the above mentioned.
                    </p>
                    <p className='mt-3 text-red-600'>(In order to initiate shootout in any robbery, you will have to make sure that there is minimum of 8 police officers in on duty also you are only permitted to use mentioned weapons)
                    </p>

                    <h2 className='text-2xl mt-6 font-bold dark:text-gray-100'>RESPECTING STAFF RULINGS</h2>
                    <p className='mt-3 text-gray-500 dark:text-gray-400'>
                        Please be respectful of our staff members, even in cases where you disagree with their decisions.<br />
                        Our staff members are volunteers who wish to help players such as yourself, but in order to enforce the server rules, they may also issue punishments or make decisions that may not favor you.<br />
                        They are humans and, admittedly, can make mistakes that could make you feel mistreated and spark a conflict, however, even when you are upset, please refrain from further escalation and we expect the same from our staff.<br />
                        As mistakes do happen, all players are welcome to submit punishment appeals and report cases of abuse on our forums where your concerns will be reviewed by a different staff member, and any potential mistakes will be corrected.

                    </p>


                    <h2 className='text-2xl mt-6 font-bold dark:text-gray-100'>DISCORD RULES</h2>
                    <p className='mt-3 text-gray-500 dark:text-gray-400'>
                        Do not spam messages.<br />
                        Never mention a staff member without valid reason.<br />
                        Try to maintain a mature and calm conversation.<br />
                        Never send a private message to anyone through this discord without getting prior consent.<br />
                        If you're streaming on any platform, come to Discord only after you've stopped streaming. Enter the discord only if you are muted/hidden<br />
                        No one is allowed to join Discord while in an active situation in order to inform the team of a Fail RP. You can start a FRP ticket after finishing the situation or if you prefer not to continue the situation due to the fail RP, you can open a FRP ticket. Leave the server and join Discord to inform staff of the situation, keep in mind that if a FRP ticket for a situation is open, you are not allowed to continue in that situation. Also, if you feel the situation is becoming FRP, always record your point of view if you are not streaming.<br />
                        NOTE : If you have any warnings in discord, you are not allowed to join the server after the specified time, if found so you will be kicked from the server without prior notice.<br />
                        These rules and guidelines are meant to create a safe and enjoyable environment for everybody. The enforcement of these rules is done entirely at staff discretion. Please make sure to have a working microphone with reasonable quality. Our staff are the ones to decide if your actions have gone against our standard of roleplay or not. Keep an open mind. It's not about winning or losing.
                    </p>
                </div>
            </motion.div>
            <FooterSticky/>
        </div>
    )
}


export default Rules