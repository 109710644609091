import React from 'react'
import { useNavigate } from 'react-router-dom'
import { RxDiscordLogo } from 'react-icons/rx'
import { SlSocialYoutube } from 'react-icons/sl'
import { FiInstagram } from 'react-icons/fi'

function Footer() {
    const navigate = useNavigate()
    return (
        <div>
            {/* <footer className="  bg-white rounded-lg  md:px-6 md:py-8 dark:bg-gray-900">

    <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
    <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">©<a onClick={()=>navigate('/')} className="hover:underline hover:cursor-pointer">Mallu Roleplay</a>. All Rights Reserved.
    </span>
</footer> */}
            <footer className="p-4 bg-white rounded-lg left-0 bottom-0 right-0  absolute  md:flex md:items-center md:justify-between md:p-6 dark:bg-gray-800">
                <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2023 <a onClick={() => navigate('/')} className="hover:underline">Mallu Roleplay™</a>. All Rights Reserved.
                </span>
                <ul className="flex flex-wrap items-center mt-3 text-sm text-gray-500 dark:text-gray-400 sm:mt-0 a gap-6">
                    <li>
                        <a href="https://discord.gg/5gC6tBQeA2" className="mr-4 hover:underline md:mr-6 hover:cursor-pointer " ><RxDiscordLogo className='text-2xl' /></a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/@MRPOfficial" className="mr-4 hover:underline md:mr-6"><SlSocialYoutube className=' text-2xl' /></a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/malluroleplay/" className="mr-4 hover:underline md:mr-6"><FiInstagram className='text-xl' /></a>
                    </li>

                </ul>
            </footer>
        </div>
    )
}

export default Footer