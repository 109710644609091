import React, {useState ,useContext } from 'react'
import {FaBars} from 'react-icons/fa'
import {Dialog} from '@headlessui/react'
import {AiOutlineClose} from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import {BsMoonStarsFill} from 'react-icons/bs'
import ThemeContext from '../DarkTheme/DarkTheme';


let NavBar = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    const navigation = [


        { name: 'Home', href: '/' },
       // { name: 'Rules', href: '/rules' },
        //  { name: 'Applications', href: '/application' },
        { name: 'Connect', href: '/connect' },
    ]
    const navigate = useNavigate();

    const { darkMode, toggleDarkMode } = useContext(ThemeContext);

    return (
        <div className={darkMode ? 'dark' : ""}>
            <section className='min-w-full sticky z-20 top-0 left-0 right-0' >
                <nav className='py-5 mb-12 flex justify-between  bg-slate-200 dark:bg-gray-800 shadow-2xl '>
                    <img src='https://cdn.discordapp.com/attachments/846752442041237524/1042404689074978826/MRP.png' className='w-14 lg:hidden sm:flex'/>
                    <h1 className='text-xl ml-5 lg:flex hidden dark:text-white '>Mallu Roleplay</h1>
                    <ul className='flex items-center mr-5'>
                    <div className="flex lg:hidden ">
                        <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 dark:text-gray-100"
                        onClick={()=>{setMobileMenuOpen(true)}}
                        >
                        <span className="sr-only">Open main menu</span>
                        <FaBars className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <li  className='cursor-pointer mr-3 rounded-xl p-2  text-black dark:text-white  hover:outline hover:outline-1 hidden lg:flex' ><a ><div className="flex items-center flex-row">
                           
                    <BsMoonStarsFill onClick={toggleDarkMode}/> 
                       </div></a></li>

                        <li  className='cursor-pointer mr-3 rounded-xl p-2  text-black dark:text-white  hover:outline hover:outline-1 hidden lg:flex' onClick={() => navigate('/')}><a ><div className="flex items-center flex-row">
                           
                            Home
                        </div></a></li>

                        {/* <li className='cursor-pointer mr-3  rounded-xl p-2  text-black dark:text-white hover:outline hover:outline-1  hidden lg:flex' onClick={() => navigate('/rules')}><a><div class="flex items-center flex-row">
                            
                            Rules
                        </div></a></li> */}
                        <li className='cursor-pointer mr-3  rounded-xl p-2  text-black dark:text-white hover:outline hover:outline-1  hidden lg:flex' onClick={() => navigate('/playhours')}><a><div className="flex items-center flex-row">
                            
                            Play Hours
                        </div></a></li>
                        {/* <li className='cursor-pointer mr-3 rounded-xl  p-2  text-black dark:text-white hover:outline hover:outline-1  hidden lg:flex'  onClick={() => navigate('/application')}><a><div class="flex items-center flex-row">
                            
                            Applications
                        </div></a></li> */}
                        <li className='cursor-pointer mr-3  rounded-xl p-2  text-black dark:text-white   hover:outline hover:outline-1  hidden lg:flex'  onClick={() => navigate('/aboutus')}><a><div className="flex items-center flex-row">
                            
                            About Us
                        </div></a></li>
                        <li className='cursor-pointer mr-3 bg-indigo-600 rounded-xl p-2  text-white dark:text-white   hover:animate-bounce  hidden lg:flex'  onClick={() => navigate('/connect')}><a><div className="flex items-center flex-row">
                            
                            Connect
                        </div></a></li>
                    </ul>
                </nav>
                <Dialog as="div" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                    <Dialog.Panel focus="true" className="fixed inset-0 z-10 overflow-y-auto bg-white bg-gray-800 px-6 py-6 lg:hidden ">
                        <div className="flex items-center justify-between">
                            <a href="#" className="-m-1.5 p-1.5">
                                <span className="sr-only">Your Company</span>
                                <img className='h-20' src="https://cdn.discordapp.com/attachments/846752442041237524/1042404689074978826/MRP.png" alt="Mrp-Logo" />
                            </a>
                            <button
                                type="button"
                                className="-m-2.5 rounded-md p-2.5 text-gray-700 dark:text-gray-100"
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                <span className="sr-only">Close menu</span>
                                <AiOutlineClose className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-gray-500/10">
                                <div className="space-y-2 py-6">
                                    {navigation.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-400/10"
                                        >
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Dialog.Panel>

                </Dialog>
            </section>
        </div>
    )
}

export default NavBar