import React , {useContext} from 'react'
import ThemeContext from '../DarkTheme/DarkTheme';
import { useNavigate } from 'react-router-dom'
import Footer from '../Footer/Footer';

let Main = ()=> {
 const {darkMode} = useContext(ThemeContext);
 const navigate = useNavigate()
  return (
    <>
    
    <div className={darkMode ? 'dark' : ""}>
        <main>
        <div className="relative px-6 lg:px-8 bg-[url('https://media.discordapp.net/attachments/804589400943755266/1047116021980483604/Discord_1000_x1000.gif')] bg-no-repeat bg-center ">
          <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56 backdrop-blur-sm">
            <div className="text-center">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl dark:text-gray-100">
               Mallu Roleplay
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-500 dark:text-gray-400">
                Welcome to Kerala's First Roleplay Server
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <a
                  href="https://discord.gg/5gC6tBQeA2"
                  className="rounded-md bg-indigo-600 px-3.5 py-1.5 text-base font-semibold leading-7 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Join Now
                </a>
                <a onClick={()=>{navigate('/aboutus')}} className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-300 hover:cursor-pointer hover:animate-bounce">
                  Learn more <span aria-hidden="true">→</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer/> 
    </div>
    </>
  )
}

export default Main
