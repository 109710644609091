import React, { useContext, useEffect, useState } from 'react';
import ThemeContext from '../DarkTheme/DarkTheme';

import axios from 'axios';
import Footer from '../Footer/Footer';
import { useNavigate } from 'react-router-dom'




let Connect = () => {
  const { darkMode } = useContext(ThemeContext);
  const [searchQuery, setSearchQuery] = useState('');
  const [status, setStatus] = useState(false)
 
  
  let access_token ;
  const navigate = useNavigate();
  useEffect(()=>{
    const cookies = document.cookie.split(';');
    access_token = cookies.find((cookie) =>
      cookie.trim().startsWith('access_token')
    );
    const server = axios.get('https://www.malluroleplay.com/api/server').then(result =>{
      setStatus(result.data)
    })
    
    
   
  
  },[])
  
  
  const handleLogin = async () => {
   
      window.location.href = "fivem://connect/64.227.128.170:30120"
    
    

  };


  return (
    <div className={darkMode ? 'dark' : ""}>
      <main className=" bg-[url('https://media.discordapp.net/attachments/804589400943755266/1047116021980483604/Discord_1000_x1000.gif')] bg-no-repeat bg-center">
        <div className="relative px-6 lg:px-8 backdrop-blur-sm">
          <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56 ">
            <div className="text-center">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl dark:text-gray-100">
                Play Now !
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-400">
                Connect Your Discord
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                {/* <a
                  href="#"
                  onClick={handleLogin}
                  disabled= {isButtonDisabled}
                  className="rounded-md bg-indigo-600 px-3.5 py-1.5 text-base font-semibold leading-7 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  
                </a> */}
                
               <button
                disabled = {!status}
                className='rounded-md bg-indigo-600 px-3.5 py-1.5 text-base font-semibold leading-7 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                onClick={handleLogin}
                
                >
                {status ? 'Connect' : 'Server is Offline'}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <div className='text-center pt-9 shadow-2xl mr-5 rounded-xl backdrop-blur-sm' id='mytable'>
          <h1 className='text-2xl dark:text-gray-100'>Play Hours</h1>

          <PlayHours/>
        </div> */}

      </main>
     <Footer/>
    </div>
  )
}

export default Connect
