import React, { useEffect, useState } from 'react'
import axios  from 'axios'
import {AiOutlineRotateRight} from 'react-icons/ai'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';


let  Admin = () => {

   const [data, setData] = useState([]);
   const navigate = useNavigate();
       useEffect(()=>{

        const token = localStorage.getItem('token');
        const check = async () => {
        const security = await axios.get('https://www.malluroleplay.com/api/protected', { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          if(response.status == 200)
          {
            fetchData();
          }
        })
        .catch((error) => {
          navigate('/error')
        });
      }
       
        const fetchData = async () => {
            const ipwhitelist = await axios.get('https://www.malluroleplay.com/api/ipwhitelisted',{
            method:'GET'
           }).then((response) => {
            setData(response.data);

           }).catch((err)=>{
            console.log(err);
           })
        }
        check();
        
         }, []);

         const handleVerify = async (item) => {
            const { userid, nickname, ip } = item;
              const response = await axios.post('https://www.malluroleplay.com/api/reverify', {
                userid: userid,
                nickname: nickname,
                ip: ip
              }, {
                headers: {
                  'Content-Type': 'application/json'
                }
              });
              console.log(response);
              if (response.data.success) {
                toast.success(`${nickname} Successfully Reverified!`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
            } else{
                toast.error('Not Verified!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    });
            }
          };
          

  return (
    
<div className="overflow-x-auto shadow-md sm:rounded-lg">
  <h1 className='bg-gray-300 text-2xl text-center dark:text-gray-50 dark:bg-gray-800'>IP WHITELISTED</h1>
  <div className="sm:w-auto">
    <table className="w-full sm:w-auto sm:min-w-full text-sm text-left text-gray-500 dark:text-gray-400">
      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr>
          <th scope="col" className="px-6 py-3">
            User ID 
          </th>
          <th scope="col" className="px-6 py-3">
            Nickname
          </th>
          <th scope="col" className="px-6 py-3">
            IP
          </th>
          <th scope="col" className="px-6 py-3">
            Authorization
          </th>
          <th scope="col" className="px-6 py-3">
            Action
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item.userid} className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
            <td className="px-6 py-4">
              {item.userid}
            </td>
            <td className="px-6 py-4">
              {item.nickname}
            </td>
            <td className="px-6 py-4">
              {item.ip}
            </td>
            <td className="px-6 py-4">
              {item.auth === 1 ? 'Authorized' : 'Unauthorized'}
            </td>
            <td className="px-6 py-4">
              <a onClick={() => handleVerify(item)} className="cursor-pointer font-medium text-blue-600 dark:text-blue-500">
                <AiOutlineRotateRight className='text-2xl' />
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
</div>

  )
}

export default Admin