import React from 'react'
import {RxDiscordLogo} from 'react-icons/rx'
import {SlSocialYoutube} from 'react-icons/sl'
import {FiInstagram} from 'react-icons/fi'
import FooterSticky from '../Footer/FooterSticky'

function AboutUs() {
    return (
        <div>
        <div className='mx-auto max-w-2xl py-32 sm:py-48 lg:py-56 rounded-xl shadow-2xl bg-gray-200'>
            <div className='flex pr-10 pl-10'>
                
                    <img className='w-60  my-auto ' src='https://media.discordapp.net/attachments/804589400943755266/1047116021980483604/Discord_1000_x1000.gif' />
                
                
                <div>
                    <h1 className='text-6xl'>
                        About Us.
                    </h1>
                    <p className='first-letter:text-2xl'>
                    We are the people who have an ideology of providing all the players a good roleplay experience and ensuring that we try to provide an un-interrupted roleplay exposure. our server is based upon a custom framework that is designed in a way keeping in mind the things that can provide a platform that can help all the players to establish a good character progression.
                    </p>
                    <br/>
                    <p>
                    We have many criminal activities which can prove to be a perfect companion for your criminal resume xD . whereas for the civilians they have so many things to do, so little time. There are simply many lots of stuff for everyone to enjoy.
                    </p>
                </div>
            </div>

        </div>
            <FooterSticky/>
        </div>
    )
}

export default AboutUs